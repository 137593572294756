import type React from "react";

export const TwoSidedFormInput = ({
  leftComponent,
  rightComponent,
}: {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
}) => {
  return (
    <div className="flex flex-wrap justify-between gap-x-4 gap-y-2 lg:flex-row lg:gap-y-4">
      <div className="min-w-[200px] flex-1">{leftComponent}</div>
      <div className="min-w-[200px] flex-1">{rightComponent}</div>
    </div>
  );
};
